import firebase from 'firebase';

  const config = {
    apiKey: "AIzaSyDqzmKZuu_U9L2RVBnNQQNI_1K3JLv0IRY",
    authDomain: "foodbytes-204ae.firebaseapp.com",
    databaseURL: "https://foodbytes-204ae-default-rtdb.firebaseio.com",
    projectId: "foodbytes-204ae",
    storageBucket: "foodbytes-204ae.appspot.com",
    messagingSenderId: "248966951596",
    appId: "1:248966951596:web:fca1978a988347d1d8e509",
    measurementId: "G-1X5DWCPEWV"
  };
 
  
  
    firebase.initializeApp(config);
    export default firebase